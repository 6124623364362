import React, { useEffect, useRef } from 'react';
import Navbar from '../layout/Navbar';
import Footer from '../layout/Footer';
import '../../styles/HomePage.css';

const HomePage = ({ onShowSurvey, onShowLuvMissionSurvey, onShowLoginPopup }) => {
  const backgroundRef = useRef(null);
  
  // Prerender and optimize background graphics
  useEffect(() => {
    // Force immediate rendering of background
    if (backgroundRef.current) {
      // Force layout recalculation to ensure background renders
      void backgroundRef.current.offsetHeight;
      
      // Add optimized rendering class after a short delay
      setTimeout(() => {
        backgroundRef.current.classList.add('background-rendered');
      }, 50);
    }
    
    // Prevent scroll jank by preloading images
    const preloadImage = (src) => {
      const img = new Image();
      img.src = src;
    };
    
    try {
      preloadImage(require("../../sunset.jpg"));
    } catch (e) {
      console.warn("Could not preload image:", e);
    }
  }, []);

  const handleFindMatch = (e) => {
    e.preventDefault();
    // Set the localStorage item to trigger the gender survey
    localStorage.setItem('luvMissionFromRecommendation', 'false');
    // Call parent component function to show the survey popup
    if (onShowSurvey) {
      onShowSurvey();
    }
  };

  const handleStartMission = (e) => {
    e.preventDefault();
    // Call parent component function to show the LUV mission survey popup
    if (onShowLuvMissionSurvey) {
      onShowLuvMissionSurvey();
    }
  };

  // Detect iOS dark mode and apply appropriate classes
  useEffect(() => {
    // Check if device is iOS
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    
    if (isIOS) {
      // Add iOS-specific class
      document.body.classList.add('ios-device');
      
      // Check for dark mode
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        document.body.classList.add('ios-dark-mode');
      }
      
      // Listen for changes in color scheme
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
        if (e.matches) {
          document.body.classList.add('ios-dark-mode');
        } else {
          document.body.classList.remove('ios-dark-mode');
        }
      });
    }
    
    // Cleanup
    return () => {
      if (isIOS && window.matchMedia) {
        window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => {});
      }
    };
  }, []);

  // Update theme color meta tag based on color scheme
  useEffect(() => {
    // Get or create the theme-color meta tag
    let themeColorMeta = document.querySelector('meta[name="theme-color"]');
    if (!themeColorMeta) {
      themeColorMeta = document.createElement('meta');
      themeColorMeta.name = 'theme-color';
      document.head.appendChild(themeColorMeta);
    }

    // Define theme colors
    const lightThemeColor = '#ffffff'; // Light mode theme color
    const darkThemeColor = '#121212';  // Dark mode theme color

    // Initial setting based on current color scheme
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      themeColorMeta.content = darkThemeColor;
    } else {
      themeColorMeta.content = lightThemeColor;
    }

    // Listen for changes in color scheme
    const colorSchemeHandler = (e) => {
      themeColorMeta.content = e.matches ? darkThemeColor : lightThemeColor;
    };

    // Add event listener
    if (window.matchMedia) {
      const colorSchemeMedia = window.matchMedia('(prefers-color-scheme: dark)');
      colorSchemeMedia.addEventListener('change', colorSchemeHandler);
      
      // Cleanup
      return () => {
        colorSchemeMedia.removeEventListener('change', colorSchemeHandler);
      };
    }
  }, []);

  return (
    <div className="home-page">
      <Navbar 
        onShowSurvey={onShowSurvey} 
        onShowLoginPopup={onShowLoginPopup}
      />
      <div className="hero-section">
        <div className="background-graphic" ref={backgroundRef}>
          <svg className="background-svg" xmlns="http://www.w3.org/2000/svg" fill="none" preserveAspectRatio="none" viewBox="0 0 1440 1045">
            <g opacity="0.15" className="light-mode-paths">
              <path fill="url(#gradient1)" d="M574.024-14.844 1554.4-21c49.73 49.656-256.96 249.617 134.98 448.139 255.21 129.271 95.36 450.601-384.16 606.961-515.216 167.99-1728.23 153.95-1771.055 183.45l-.898 2.45c-.553-.85-.242-1.67.898-2.45L-12.068-21z" />
              <path fill="url(#gradient2)" d="M-276.018-43.977 824.573-53c55.828 72.784 331.237 414.417 0 733.555-370.851 357.305-490.48 60.453-1232.183 731.755-345.632 312.82-989.25 307.15-1034.83 346.71l-1.96 6.98c-1.24-2.5-.54-4.81 1.96-6.98L-933.98-53z" />
              <path fill="url(#gradient3)" d="M259.126 545.017C515.61 303.398 250.814 55.145 86.356-38.779L-613.63-74-692 1526.81c265.983 120.93 748.077 280.72 548.59-47.54-249.359-410.33 81.932-632.229 402.536-934.253" />
            </g>
            <g opacity="0.15" className="dark-mode-paths">
              <path fill="url(#dark-gradient1)" d="M574.024-14.844 1554.4-21c49.73 49.656-256.96 249.617 134.98 448.139 255.21 129.271 95.36 450.601-384.16 606.961-515.216 167.99-1728.23 153.95-1771.055 183.45l-.898 2.45c-.553-.85-.242-1.67.898-2.45L-12.068-21z" />
              <path fill="url(#dark-gradient2)" d="M-276.018-43.977 824.573-53c55.828 72.784 331.237 414.417 0 733.555-370.851 357.305-490.48 60.453-1232.183 731.755-345.632 312.82-989.25 307.15-1034.83 346.71l-1.96 6.98c-1.24-2.5-.54-4.81 1.96-6.98L-933.98-53z" />
              <path fill="url(#dark-gradient3)" d="M259.126 545.017C515.61 303.398 250.814 55.145 86.356-38.779L-613.63-74-692 1526.81c265.983 120.93 748.077 280.72 548.59-47.54-249.359-410.33 81.932-632.229 402.536-934.253" />
            </g>
            <defs>
              {/* Light mode gradients */}
              <linearGradient id="gradient1" x1="1405.21" x2="1340.51" y1="-27.156" y2="959.445" gradientUnits="userSpaceOnUse">
                <stop stopColor="#F54B42" />
                <stop offset="0.338" stopColor="#E53E3E" />
                <stop offset="0.7" stopColor="#9933FF" />
                <stop offset="1" stopColor="#98E4BE" />
              </linearGradient>
              <linearGradient id="gradient2" x1="657.092" x2="533.655" y1="-62.023" y2="1379.74" gradientUnits="userSpaceOnUse">
                <stop stopColor="#E74C3C" />
                <stop offset="0.338" stopColor="#D35EBB" />
                <stop offset="0.7" stopColor="#7D3AC1" />
                <stop offset="1" stopColor="#98E4BE" />
              </linearGradient>
              <linearGradient id="gradient3" x1="-693.781" x2="194.39" y1="497.468" y2="1081.5" gradientUnits="userSpaceOnUse">
                <stop stopColor="#9933FF" />
                <stop offset="0.5" stopColor="#9933FF" />
                <stop offset="0.8" stopColor="#5D8EF3" />
                <stop offset="1" stopColor="#8ED4B5" />
              </linearGradient>
              
              {/* Dark mode gradients - deeper, richer colors that work well on dark backgrounds */}
              <linearGradient id="dark-gradient1" x1="1405.21" x2="1340.51" y1="-27.156" y2="959.445" gradientUnits="userSpaceOnUse">
                <stop stopColor="#8B0000" />
                <stop offset="0.338" stopColor="#800080" />
                <stop offset="0.7" stopColor="#4B0082" />
                <stop offset="1" stopColor="#006400" />
              </linearGradient>
              <linearGradient id="dark-gradient2" x1="657.092" x2="533.655" y1="-62.023" y2="1379.74" gradientUnits="userSpaceOnUse">
                <stop stopColor="#8B0000" />
                <stop offset="0.338" stopColor="#8E44AD" />
                <stop offset="0.7" stopColor="#4A235A" />
                <stop offset="1" stopColor="#145A32" />
              </linearGradient>
              <linearGradient id="dark-gradient3" x1="-693.781" x2="194.39" y1="497.468" y2="1081.5" gradientUnits="userSpaceOnUse">
                <stop stopColor="#4B0082" />
                <stop offset="0.5" stopColor="#4B0082" />
                <stop offset="0.8" stopColor="#1A5276" />
                <stop offset="1" stopColor="#145A32" />
              </linearGradient>
            </defs>
          </svg>
          <div className="background-fade"></div>
        </div>
        <div className="content-container">
          <div className="text-content">
            <h1>
              <div className="pixelated-text">Turn your date</div>
              <div className="pixelated-gradient-text">into an adventure.</div>
            </h1>
            <div className="description">
              Pick a LUV mission or create your own. Complete it in person with someone you know or find a match here.
            </div>
            <div className="button-container">
              <a href="#" className="cta-button" onClick={handleStartMission}>Start a LUV mission</a>
              <a href="#" className="cta-button secondary-button" onClick={handleFindMatch}>Find a LUV match</a>
            </div>
          </div>
          <div className="mission-card">
            <div className="mission-card-content">
              <h3 className="pixelated-gradient-text">Your LUV Mission</h3>
              <p>Go on a sunset hike and take a photo at the summit</p>
              <img 
                src={require("../../sunset.jpg")} 
                alt="Sunset hike view" 
                className="mission-card-image" 
              />
              <div className="mission-card-footer">
                <button 
                  className="mission-action-button" 
                  onClick={handleFindMatch}
                >
                  Let's go!
                </button>
                <span className="mission-likes">❤️ 452</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer onShowSurvey={onShowSurvey} />
    </div>
  );
};

export default HomePage; 