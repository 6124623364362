import React, { useState, useRef, useEffect } from 'react';
import '../../styles/LoginPopup.css';

const LoginPopup = ({ onClose, onShowSurvey }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const popupRef = useRef(null);

  // Close when clicking outside the popup
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Show message that sign up is recommended instead
    alert('Incorrect login credentials.');
  };

  const handleSignUpClick = (e) => {
    e.preventDefault();
    onClose(); // Close the login popup
    if (onShowSurvey) {
      // Set the localStorage item to trigger the gender survey
      localStorage.setItem('luvMissionFromRecommendation', 'false');
      // Show the gender survey
      onShowSurvey();
    }
  };

  return (
    <div className="login-popup-overlay">
      <div className="login-popup" ref={popupRef}>
        <button className="close-button" onClick={onClose}>&times;</button>
        
        <div className="login-popup-header">
          <h2>Sign In</h2>
        </div>
        
        <div className="login-popup-message">
          <p>
            <a href="#" className="signup-link" onClick={handleSignUpClick}>Sign up</a> for the waitlist to get early access.
          </p>
        </div>
        
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
          </div>
          
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              required
            />
          </div>
          
          <button type="submit" className="login-button">Sign In</button>
        </form>
      </div>
    </div>
  );
};

export default LoginPopup; 