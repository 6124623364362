import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import HomePage from './components/home/HomePage';
import Survey from './components/Survey';
import LuvMissionSurvey, { LearnMorePopup } from './components/LuvMissionSurvey';
import LoginPopup from './components/auth/LoginPopup';
import { db } from './firebase';
import { doc, getDoc } from 'firebase/firestore';

function App() {
  const [showSurvey, setShowSurvey] = useState(false);
  const [showLuvMissionSurvey, setShowLuvMissionSurvey] = useState(false);
  const [showLearnMore, setShowLearnMore] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [sharedMission, setSharedMission] = useState(null);
  const [loadingMission, setLoadingMission] = useState(false);
  const sharedMissionDetailsRef = useRef(null);

  // Check for shared mission in URL
  useEffect(() => {
    const checkForSharedMission = async () => {
      const path = window.location.pathname;
      const match = path.match(/^\/mission\/([a-zA-Z0-9]+)$/);
      
      if (match && match[1]) {
        const missionId = match[1];
        setLoadingMission(true);
        
        try {
          const missionDoc = await getDoc(doc(db, 'missions', missionId));
          
          if (missionDoc.exists()) {
            // Get mission data and handle potential legacy data format
            const missionData = missionDoc.data();
            
            // Handle legacy missions that might not have all the fields
            // by providing fallback values
            const processedMission = {
              id: missionDoc.id,
              ...missionData,
              
              // Ensure location details exists with expected fields
              locationDetails: missionData.locationDetails || {
                name: missionData.location || "Mission Location",
                description: missionData.locationDesc || "Your LUV mission location",
                formattedAddress: "San Francisco, CA",
                coordinates: { lat: 37.7749, lng: -122.4194 },
                notes: "Outdoor venue, free admission. Limited parking available. The north end is clothing-optional. Accessible by MUNI 29 bus. Strong currents make swimming unsafe, but perfect for romantic shoreline walks. Bring a blanket and picnic."
              },
              
              // Ensure protocol and challenge fields exist
              missionProtocol: missionData.missionProtocol || missionData.qualifier || "Complete your mission qualifier",
              missionChallenge: missionData.missionChallenge || "Designed uniquely for each couple by LUV and revealed in person."
            };
            
            setSharedMission(processedMission);
          } else {
            console.error('Mission not found');
          }
        } catch (error) {
          console.error('Error loading shared mission:', error);
        } finally {
          setLoadingMission(false);
        }
      }
    };
    
    checkForSharedMission();
  }, []);

  const handleShowSurvey = () => {
    setShowSurvey(true);
  };

  const handleShowLuvMissionSurvey = () => {
    setShowLuvMissionSurvey(true);
  };

  const handleShowLoginPopup = () => {
    setShowLoginPopup(true);
  };

  const handleCloseLoginPopup = () => {
    setShowLoginPopup(false);
  };

  const handleSurveyClose = () => {
    // First, check if we need to show the LuvMissionSurvey again
    const shouldReturn = localStorage.getItem('luvMissionFromRecommendation');
    
    if (shouldReturn === 'true') {
      // Make sure the LuvMissionSurvey is visible immediately (no animations)
      const luvSurvey = document.querySelector('.survey-overlay:not(.higher-survey)');
      if (luvSurvey) {
        // Show the Luv Mission Survey again
        luvSurvey.style = ''; // Clear all inline styles
        luvSurvey.classList.remove('slide-out-top');
        luvSurvey.style.visibility = 'visible';
        luvSurvey.style.display = 'flex';
        luvSurvey.style.opacity = '1';
        luvSurvey.style.zIndex = '1001';
        luvSurvey.style.transform = 'none';
        
        // Also make sure the container is visible
        const container = luvSurvey.querySelector('.survey-container');
        if (container) {
          container.style = ''; // Clear all inline styles
          container.classList.remove('fade-out');
          container.style.opacity = '1';
          container.style.transform = 'none';
        }
      } else {
        // If not found, explicitly show it from React state
        console.log("Reactivating LuvMissionSurvey from state");
        setShowLuvMissionSurvey(true);
      }
    }
    
    // Immediately hide the gender survey
    setShowSurvey(false);
  };

  const handleLuvMissionSurveyClose = () => {
    setShowLuvMissionSurvey(false);
  };

  const handleLearnMoreClose = () => {
    setShowLearnMore(false);
  };

  const handleLearnMoreTry = () => {
    setShowLearnMore(false);
    setShowLuvMissionSurvey(true);
  };

  const handleFindMatch = () => {
    // Check if coming from recommendation link
    const fromRecommendation = localStorage.getItem('luvMissionFromRecommendation') === 'true';
    
    // Get references to both surveys (before setting state)
    const luvSurvey = document.querySelector('.survey-overlay:not(.higher-survey)');
    
    // First set up the transition on the current survey
    if (luvSurvey) {
      // Apply a nice slide-out animation
      luvSurvey.classList.add('slide-out-top');
      luvSurvey.style.opacity = '0';
      luvSurvey.style.transform = 'translateY(-15px)';
    }
    
    // Set state to show the new survey immediately - it will be stacked on top
    setShowSurvey(true);
    
    // After React updates the DOM with the new survey
    setTimeout(() => {
      // Get reference to new gender survey
      const genderSurvey = document.querySelector('.higher-survey');
      
      if (genderSurvey) {
        // Set initial state for animation
        genderSurvey.style.opacity = '0';
        genderSurvey.style.transform = 'translateY(-15px)';
        genderSurvey.style.zIndex = '3500';
        
        // Force a reflow to ensure the initial state is applied
        void genderSurvey.offsetHeight;
        
        // Then trigger the animation after a very short delay
        setTimeout(() => {
          genderSurvey.style.opacity = '1';
          genderSurvey.style.transform = 'translateY(0)';
          
          // Apply a nice animation class
          genderSurvey.classList.add('slide-in-top');
          
          // Also animate the container
          const genderContainer = genderSurvey.querySelector('.survey-container');
          if (genderContainer) {
            genderContainer.style.opacity = '1';
            genderContainer.style.transform = 'translateY(0)';
          }
        }, 20);
      }
      
      // Handle the LuvMissionSurvey (with slight delay to let the animation start)
      setTimeout(() => {
        if (luvSurvey) {
          if (fromRecommendation) {
            // Keep mounted but hide it
            luvSurvey.style.visibility = 'hidden';
            luvSurvey.style.zIndex = '1000';
          } else {
            // Unmount after animation completes
            setTimeout(() => {
              setShowLuvMissionSurvey(false);
            }, 300);
          }
        }
      }, 50);
    }, 10);
  };

  // Handle the case when a mission is shared
  if (loadingMission) {
    return (
      <div className="shared-mission-overlay">
        <div className="shared-mission-container">
          <div className="loading-indicator">Loading mission...</div>
        </div>
      </div>
    );
  }

  if (!loadingMission && sharedMission) {
    return (
      <div className="shared-mission-overlay" style={{
        backgroundColor: '#fae6ff',
        backdropFilter: 'blur(10px)'
      }}>
        <div className="shared-mission-container" style={{
          boxShadow: '0 0 30px rgba(147, 51, 234, 0.6), 0 0 80px rgba(147, 51, 234, 0.4), 0 0 120px rgba(147, 51, 234, 0.2)',
          backgroundColor: 'white',
          borderRadius: '32px',
          position: 'relative',
          zIndex: '2',
          maxWidth: '520px',
          width: '100%',
          overflow: 'hidden'
        }}>
          <button 
            className="shared-mission-close" 
            onClick={() => {
              // Clear the mission from URL and state
              window.history.pushState({}, '', '/');
              setSharedMission(null);
            }}
          >
            &times;
          </button>
          
          <div 
            className="shared-mission-details"
            ref={sharedMissionDetailsRef}
            style={{
              padding: '2rem',
              maxHeight: '90vh',
              overflowY: 'auto'
            }}
          >
            <div className="shared-mission-header">
              <div className="shared-mission-title">Your LUV Mission</div>
              <div className="shared-mission-name">{sharedMission.title}</div>
            </div>
            
            <div className="shared-mission-info">
              <div className="shared-mission-section">
                <div className="shared-mission-section-title">Mission Location</div>
                <div className="shared-mission-section-content">
                  {sharedMission.locationDetails.name}
                </div>
                <div className="shared-mission-section-description">
                  {sharedMission.locationDetails.description}
                </div>
                <div className="shared-mission-section-address">
                  {sharedMission.locationDetails.formattedAddress}
                </div>
              </div>
              
              <div className="shared-mission-section">
                <div className="shared-mission-section-title">Mission Protocol</div>
                <div className="shared-mission-section-content">
                  {sharedMission.missionProtocol}
                </div>
              </div>
              
              <div className="shared-mission-section">
                <div className="shared-mission-section-title">Mission Challenge</div>
                <div className="shared-mission-section-content">
                  {sharedMission.missionChallenge}
                </div>
              </div>
              
              <div className="shared-mission-section mission-notes">
                <div className="shared-mission-section-title">Notes</div>
                <div className="shared-mission-section-content">
                  {sharedMission.locationDetails.notes || "No additional notes for this mission."}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="App">
      <HomePage 
        onShowSurvey={handleShowSurvey}
        onShowLuvMissionSurvey={handleShowLuvMissionSurvey}
        onShowLoginPopup={handleShowLoginPopup}
      />
      
      {showSurvey && (
        <Survey 
          onClose={handleSurveyClose}
          className="higher-survey"
        />
      )}
      
      {showLuvMissionSurvey && (
        <LuvMissionSurvey 
          onClose={handleLuvMissionSurveyClose} 
          onFindMatch={handleFindMatch}
        />
      )}
      
      {showLearnMore && (
        <LearnMorePopup 
          onClose={handleLearnMoreClose}
          onTryNow={handleLearnMoreTry}
        />
      )}

      {showLoginPopup && (
        <LoginPopup 
          onClose={handleCloseLoginPopup} 
          onShowSurvey={handleShowSurvey}
        />
      )}
    </div>
  );
}

export default App;
