import React, { useState, useEffect } from 'react';
import '../../styles/Navbar.css';

const Navbar = ({ onShowSurvey, onShowLoginPopup }) => {
  const [isHidden, setIsHidden] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const isNearTop = currentScrollY < 20;
      
      // Show navbar only when near top, hide when scrolling down
      if (isNearTop) {
        if (isHidden) {
          setIsTransitioning(true);
          setIsHidden(false);
          setTimeout(() => setIsTransitioning(false), 500); // Match transition duration
        }
      } else if (currentScrollY > lastScrollY) {
        if (!isHidden) {
          setIsTransitioning(true);
          setIsHidden(true);
          setTimeout(() => setIsTransitioning(false), 500); // Match transition duration
        }
      }
      
      setLastScrollY(currentScrollY);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY, isHidden]);
  
  const handleExploreMissions = (e) => {
    e.preventDefault();
    localStorage.setItem('luvMissionFromRecommendation', 'false');
    if (onShowSurvey) {
      onShowSurvey();
    }
  };

  const handleSignIn = (e) => {
    e.preventDefault();
    if (onShowLoginPopup) {
      onShowLoginPopup();
    }
  };

  return (
    <nav className={`navbar ${isHidden ? 'hidden' : ''} ${isTransitioning ? 'transitioning' : ''}`}>
      <div className="container">
        <div className="navbar-left">
          <a href="/" className="logo-link">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 536" width="120" height="34" className="logo-icon">
              {/* L */}
              <path d="M170 35.5c0 3.5-2.9 77.6-6.5 164.5S157 360.1 157 362.5v4.5h106v3.7c0 6-3.9 101.8-4.6 112l-.6 9.3H22.3l.4-7.3C23.4 471.4 41 38.5 41 33.7V29h129v6.5z" fill="currentColor"/>
              
              {/* U */}
              <path d="M423.5 37.7c-1.9 29.5-8.6 210.8-8.3 226.5.3 17.1.5 18.9 2.4 21.5 1.2 1.5 3.1 2.9 4.4 3.1 9.4 1.4 12.7-7.5 15-40.8 1-15.3 9-205.2 9-214.7V29h129v5.8c0 5.5-5.8 149.3-8 199.7-3.6 80-13.5 131.2-33.6 173.4-14 29.4-36.8 56.7-60.9 73.1-19 12.9-35.9 18.2-57.5 18.2-18.6 0-34.6-4.9-50.8-15.4-42.5-27.7-66.7-73-74.6-139.8-4.9-40.6-4.8-72.3.4-204 2.3-56.4 4.3-104.4 4.6-106.8l.5-4.2h129l-1 8.7z" fill="currentColor"/>
              
              {/* V */}
              <path d="M722 31.2c.3 1.3 5 33.6 10.5 71.8 10.4 72.3 11 76.3 11.6 75.8.1-.2 3.7-15.6 8-34.3 4.2-18.7 11.8-52.2 16.8-74.5l9.2-40.5 63.7-.3c40.9-.1 63.7.1 63.7.8 0 .5-6.7 28.3-14.9 61.7s-29.4 120.2-47 192.8c-17.7 72.6-36.3 148.9-41.4 169.5l-9.3 37.5-61.7.3-61.7.2-.4-2.2c-.9-4.1-18.2-110.2-37.6-230.8-10.5-65.2-23.1-143-28-172.9-5-29.9-9-55-9-55.7 0-1.2 10.4-1.4 63.5-1.4h63.5l.5 1.2z" fill="currentColor"/>
              
              {/* Heart (middle symbol) */}
              <path d="M918.1 341.6c11 3.8 19.9 12.6 30.5 30 .9 1.5 1.4 1.1 3.9-3.2 17.8-30.6 45.2-37.7 67.6-17.6 29 26 17.8 67.2-32.6 119.1-11.9 12.3-36.2 34.1-38 34.1s-26.1-21.8-38-34.1c-50.4-51.9-61.6-93.1-32.6-119.1 11.6-10.5 26-13.8 39.2-9.2z" fill="currentColor"/>
              
              {/* W */}
              <path d="M1133 30c0 .5 2.4 16.4 5.4 35.2 3 18.9 8 50.5 11.1 70.3s5.9 36.7 6.2 37.5 8.7-31.1 18.7-71l18.2-72.5 45-.3 44.9-.2 5.3 29.7c15.7 88.9 20.6 116.2 21 115.9.2-.2 5.2-21.9 11.3-48.2 6-26.4 13.4-59 16.6-72.7l5.6-24.7h62.4c49.2 0 62.3.3 62.3 1.2 0 .7-2.5 11.4-5.6 23.8-9.2 37.7-22.1 90.5-55.4 228.5-30.1 124.6-47.8 197.3-50.1 205.7l-1 3.8h-94.8l-1-5.3c-1.2-5.8-7.3-38.5-21.6-115.7-5.2-28.3-9.9-52.6-10.3-53.9-.8-2.4-5.7 16-36.3 138.2l-9.3 36.7-47.5-.2-47.5-.3-3.3-19c-1.8-10.5-11.9-69.6-22.3-131.5s-23.8-139.7-29.5-173c-12.6-73.1-23.5-137.4-23.5-138.3 0-.4 28.1-.7 62.5-.7 39.3 0 62.5.4 62.5 1z" fill="currentColor"/>
              
              {/* T */}
              <path d="M1720 33.7c0 8.7-8.1 204.8-8.5 206-.3 1-7.4 1.3-30.7 1.3h-30.4l-4.7 118.7c-2.6 65.4-5.1 121.8-5.4 125.5l-.6 6.8h-128.9l.7-11.8c.8-16.2 9.5-229.3 9.5-234.8V241h-61.2l.6-9.3c.3-5 2.2-49.5 4.1-98.7s3.8-92.8 4.1-96.8l.6-7.2H1720v5z" fill="currentColor"/>
              
              {/* F */}
              <path d="M1979 33.7c.1 8.2-4.1 111.6-4.6 116l-.5 4.3h-103.7l-.6 8.7c-.3 4.9-.8 15.5-1.1 23.8-.3 8.2-.8 20.1-1.1 26.2l-.6 11.3h77.2v4.7c.1 8-4 109.4-4.6 115.1l-.5 5.2h-76.7l-.6 9.2c-.3 5.1-1.5 32-2.6 59.8s-2.2 55.8-2.6 62.2l-.6 11.8h-129l.6-11.3c.5-8.2 18.6-448 18.6-451.2 0-.3 52.4-.5 116.5-.5h116.5v5z" fill="currentColor"/>
            </svg>
          </a>
        </div>
        <div className="navbar-right">
          <div className="nav-links">
            <a href="#" className="nav-link" onClick={handleExploreMissions}>
              Explore missions
              <svg 
                width="24" 
                height="24" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2" 
                strokeLinecap="round" 
                strokeLinejoin="round" 
                className="arrow-icon"
              >
                <path d="M7 17 17 7M7 7h10v10" />
              </svg>
            </a>
          </div>
          <div className="auth-buttons">
            <a href="#" className="sign-in-button" onClick={handleSignIn}>Sign In</a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar; 